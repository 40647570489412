define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-gb", {
    "about": {
      "index": "The Practise",
      "where": "Where?",
      "who": "Who are we?",
      "why": "Why Vida?"
    },
    "calendar": {
      "index": "Agenda"
    },
    "contact": {
      "appointment": "Scheduling an appointment / registering",
      "emergency": "Emergency",
      "index": "Contact"
    },
    "forms": {
      "field_invalid": "Invalid input",
      "field_invalid_email": "Enter a valid e-mail address",
      "field_invalid_oneoff": "Required field ",
      "field_required": "Required field",
      "field_too_long": "Maximum {value} characters long",
      "field_too_short": "Minimum {value} characters long",
      "fields_required": "* required fields"
    },
    "home": {
      "agenda": {
        "cta1": "View",
        "cta2": "the agenda"
      },
      "call": "Questions?\n'<a href='{link}'>Call</a>' me back",
      "goto": {
        "abc": {
          "button": "To ABC",
          "title": "See everything you need to know from A to Z in a row"
        },
        "week": {
          "button": "Read by week",
          "title": "Or go directly to your week and read all about it"
        }
      },
      "news": {
        "cta1": "Read",
        "cta2": "all the news"
      },
      "sign-up": "Pregnant?\nSchedule an '<a href='{link}'>appointment</a>",
      "the-practice": {
        "title": "The Practise",
        "where": "Contact",
        "who": "About us",
        "why": "Experiences"
      },
      "welcome": {
        "lead": "Vida's experienced midwives will do everything possible to make sure your pregnancy, delivery and postpartum period go according to your wishes!<br><br>What can you expect?",
        "title": "Welcome"
      }
    },
    "labels": {
      "common": {
        "close": "Close",
        "more-info": "More info",
        "read-more": "Read more"
      },
      "components": {
        "back-button": "Previous page",
        "cookie-wall": {
          "progress": "We would like to remember your due date in a private cookie on your computer, but your current cookie settings do not allow this.",
          "text": "Here should be an embedded media player, but your cookie settings do not allow this. '<br>'Click '<'a href=\"{link}\" target=\"_blank\" rel=\"noopener norefer\"'>'on this link'</a>' to watch the media on the external website."
        },
        "cookies": {
          "accept": "accept all cookies",
          "confirm": "Confirm your cookie settings",
          "customize": "Change your cookie settings",
          "edit": "Change your cookie settings",
          "embeds": "Allow embedded third-party media players<br>(YouTube, Vimeo, etc.)",
          "private": "Allow this website to store personal data on your own computer or device so that we can show you your current week",
          "required": "Allow functional and anonymous analysis cookies<br>(mandatory)",
          "text": "We use cookies to optimize your user experience and analyze (anonymously) our website & web app use. If you agree to our use of cookies, please click \"Accept all cookies",
          "title": "Cookies"
        },
        "emergency-contact": {
          "about": "What is an emergency?",
          "title": "Emergency"
        },
        "main-menu": {
          "button": "Show/Hide menu'",
          "home": "Go to the homepage",
          "languages": {
            "en": "View our website in English",
            "nl": "Bekijk de site in het Nederlands"
          }
        },
        "pregnancy-preview": {
          "details": "Save your due date (securely), so that on your next visit you may immediately read what things are important for your week.",
          "goto": "Read more about {week}",
          "remember": "Remember my week",
          "saved": "You have saved as your due date <b>{date}</b>.<br>Click the button to read what is currently important in your pregnancy.",
          "saved-title": "Welcome back!"
        },
        "site-footer": {
          "address": "Address data",
          "buttons": "Main menu (repetition)",
          "contact": "Contact",
          "copyright": "© Copyright {year}<br>All rights reserved",
          "emergency": "Emergency phone number",
          "menu": "Secondary menu",
          "phone-email": "Phone number and email address",
          "self": "Footer"
        },
        "store-week": {
          "delete": "Delete this date",
          "details": "When you save your due date, you can easily find what is important for your pregnancy at that moment when you next visit",
          "details-saved": "You saved <b>{date}</b> as the due date.",
          "my-date": "My due date",
          "placeholder": "month day, year",
          "save": "Save my date",
          "saved": "Your date has been saved successfully",
          "title": "Remember my week"
        }
      }
    },
    "news": {
      "index": "News"
    },
    "pregnancy": {
      "abc": {
        "index": "Pregnancy ABC",
        "letter": "Letter: {letter}",
        "short": "ABC"
      },
      "index": "Pregnant?",
      "week": {
        "index": "Pregnancy by week",
        "myweek": "My week",
        "nr": "My week: {week}",
        "preview": "Pregnancy\nby week",
        "short": "Timeline"
      }
    },
    "pwa": {
      "install": "Install our Web app",
      "instructions": {
        "android": "<li>Tap the menu with the three dots in the top right corner of the screen.</li><li>Select 'Add to home screen' from the menu.</li><li>Customise the app name if required and then tap 'Add' in the top right corner.</li><li>The website will now be added to your home screen as an app icon.</li>",
        "ios": "<li>Tap the 'Share' button at the bottom of the screen (it looks like a square with an upward arrow).</li><li>Scroll down and select 'Add to home screen.' </li><li>Customise the name of the app if desired and then tap 'Add' in the top right corner. </li><li>The website will now be added to your home screen as an app icon.</li>"
      },
      "readmore": "Read more",
      "title": {
        "android": "Instructions for Android",
        "general": "Install our Web app",
        "ios": "Instructions for iOS"
      }
    },
    "slogan": "Pregnant?\nVida grows with you!",
    "title": "Hello, We are Vida Midwives in Bilthoven, de Bilt, Maartensdijk and the surrounding area",
    "utils": {
      "dateFormat": "F J, Y"
    }
  }], ["nl-nl", {
    "about": {
      "index": "De praktijk",
      "where": "Waar?",
      "who": "Wie zijn we?",
      "why": "Waarom Vida?"
    },
    "calendar": {
      "index": "Agenda"
    },
    "contact": {
      "appointment": "Afspraak maken / aanmelden",
      "emergency": "Spoed",
      "index": "Contact"
    },
    "forms": {
      "field_invalid": "Ongeldige invoer",
      "field_invalid_email": "Voer een geldig e-mailadres in",
      "field_invalid_oneoff": "Verplicht veld",
      "field_required": "Verplicht veld",
      "field_too_long": "Maximaal {value} karakters lang",
      "field_too_short": "Minimaal {value} karakters lang",
      "fields_required": "* verplichte velden"
    },
    "home": {
      "agenda": {
        "cta1": "Bekijk",
        "cta2": "de agenda"
      },
      "call": "Vragen?\n'<a href='{link}'>Bel</a>' mij terug",
      "goto": {
        "abc": {
          "button": "Naar ABC",
          "title": "Bekijk alles wat je moet weten van A tot Z op een rij"
        },
        "week": {
          "button": "Lees per week",
          "title": "Of ga direct naar jouw week en lees er alles over"
        }
      },
      "news": {
        "cta1": "Lees",
        "cta2": "al het nieuws"
      },
      "sign-up": "Zwanger?\nSchrijf je '<a href='{link}'>hier</a>' in",
      "the-practice": {
        "title": "De praktijk",
        "where": "Contact",
        "who": "Over ons",
        "why": "Ervaringen"
      },
      "welcome": {
        "lead": "Bij Vida verloskundigen zullen we er alles aan doen om jouw zwangerschap, bevalling en kraamtijd zo fijn mogelijk te laten verlopen.<br>Jouw wensen en behoeften staan daarbij voorop.<br><br>Wat kan je verwachten?",
        "title": "Welkom"
      }
    },
    "labels": {
      "common": {
        "close": "Sluiten",
        "more-info": "Meer info",
        "read-more": "Lees meer"
      },
      "components": {
        "back-button": "Vorige pagina",
        "cookie-wall": {
          "progress": "We willen graag je uitgerekende datum opslaan in een privé cookie op jouw computer, maar je huidige cookie instellingen staan dit niet toe.",
          "text": "Hier hoort een ingebedde mediaspeler te staan, maar de cookie-instellingen staan dat niet toe.'<br>'Klik '<'a href=\"{link}\" target=\"_blank\" rel=\"noopener norefer\"'>'op deze link'</a>' om de media op de externe site te bekijken."
        },
        "cookies": {
          "accept": "Accepteer alle cookies",
          "confirm": "Bevestig je cookie instellingen",
          "customize": "Wijzig je cookie instellingen",
          "edit": "Wijzig je cookie instellingen",
          "embeds": "Sta ingebedde mediaspelers van derden toe'<br>' (YouTube, Vimeo, etc.)",
          "private": "Sta deze website toe persoonsgegevens op te slaan op jouw eigen computer of apparaat, zodat we je jouw huidige week kunnen laten zien",
          "required": "Sta functionele en anonieme analyse cookies toe'<br>' (verplicht)",
          "text": "Wij gebruiken cookies om jouw gebruikservaring te optimaliseren en (geanonimiseerd) het gebruik van onze website & web-app te analyseren. Als je akkoord gaat met ons gebruik van cookies, klik je op “Accepteer alle cookies”.",
          "title": "Cookies"
        },
        "emergency-contact": {
          "about": "Wat is spoed?",
          "title": "Spoed "
        },
        "main-menu": {
          "button": "Toon/verberg menu",
          "home": "Ga naar de homepage",
          "languages": {
            "en": "View our website in English",
            "nl": "Bekijk de site in het Nederlands"
          }
        },
        "pregnancy-preview": {
          "details": "Sla je je uitgerekende datum (veilig) op, dan lees je bij een volgend bezoek meteen wat voor jouw week belangrijk is. ",
          "goto": "Lees meer over {week}",
          "remember": "Onthoud mijn week",
          "saved": "Je hebt als uitgerekende datum <b>{date}</b> opgeslagen.<br>Klik op de knop om te lezen wat op dit moment belangrijk is in je zwangerschap.",
          "saved-title": "Welkom terug!"
        },
        "site-footer": {
          "address": "Adresgegevens",
          "buttons": "Primair menu (herhaling)",
          "contact": "Contact",
          "copyright": "© Copyright {year}<br>Alle rechten voorbehouden",
          "emergency": "Spoed telefoon nummer",
          "menu": "Secundair menu",
          "phone-email": "Telefoonnummer en e-mailadres",
          "self": "Footer"
        },
        "store-week": {
          "delete": "Wis deze datum",
          "details": "Wanneer je je uitgerekende datum opslaat, vind je bij een volgend bezoek makkelijk terug wat voor jouw zwangerschap op dat moment belangrijk is",
          "details-saved": "Je hebt als uitgerekende datum <b>{date}</b> opgeslagen. ",
          "my-date": "Mijn uitgerekende datum",
          "placeholder": "dag maand jaar",
          "save": "Sla mijn datum op",
          "saved": "Je datum is succesvol opgeslagen",
          "title": "Onthoud mijn week"
        }
      }
    },
    "news": {
      "index": "Nieuws"
    },
    "pregnancy": {
      "abc": {
        "index": "Zwangerschaps ABC",
        "letter": "Letter: {letter}",
        "short": "ABC"
      },
      "index": "Zwanger?",
      "week": {
        "index": "Zwangerschap per week",
        "myweek": "Mijn week",
        "nr": "Mijn week: {week}",
        "preview": "Zwangerschap\nper week",
        "short": "Tijdlijn"
      }
    },
    "pwa": {
      "install": "Installeer onze Web app",
      "instructions": {
        "android": "<li>Tik op het menu met de drie stippen in de rechterbovenhoek van het scherm.</li><li>Selecteer 'Toevoegen aan startscherm' in het menu.</li><li>Pas indien gewenst de naam van de app aan en tik vervolgens op 'Toevoegen' in de rechterbovenhoek.</li><li>De website wordt nu als een app-pictogram aan uw startscherm toegevoegd.</li>",
        "ios": "<li>Tik op de knop 'Delen' onderaan het scherm (het ziet eruit als een vierkant met een pijl omhoog).</li><li>Scroll omlaag en selecteer 'Voeg toe aan beginscherm.'</li><li>Pas indien gewenst de naam van de app aan en tik vervolgens op 'Voeg toe' in de rechterbovenhoek.</li><li>De website wordt nu als een app-pictogram aan uw startscherm toegevoegd.</li>"
      },
      "readmore": "lees meer",
      "title": {
        "android": "Instructies voor het toevoegen aan startscherm op Android",
        "general": "Installeer onze Web App",
        "ios": "Instructies voor het toevoegen aan startscherm op iOS"
      }
    },
    "slogan": "Zwanger?\nVida groeit met je mee!",
    "title": "Hallo,\nWij zijn Vida Verloskundigen in Bilthoven, de Bilt, Maartensdijk en omgeving",
    "utils": {
      "dateFormat": "j F Y"
    }
  }]];
  _exports.default = _default;
});